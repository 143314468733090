import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Text, Form } from 'grommet';
import { Formik, Field, ErrorMessage } from 'formik';

import Loading from '../Loading';
import InputText from '../Inputs/InputText';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import useModalNavigation from '../useModalNavigation';
import { logout } from '../../state/industry/industrySlice';
import isValidEmailAddress from '../../lib/isValidEmailAddress';

const FormForgotPassword = ({ returnTo }) => {
  const dispatch = useDispatch();
  const { navigate } = useModalNavigation();
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(false);

  return (
    <Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values['email'] || values['email'] === '') {
            errors['email'] = 'Required';
          } else if (!isValidEmailAddress(values['email'])) {
            errors['email'] = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            dispatch(logout);
            setFormError(null);
            await fetch(process.env.GATSBY_BACKDROP_API_FORGOT_PASSWORD_URL, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: values.email,
              }),
            });
            setSubmitting(false);
            if (returnTo) {
              navigate(returnTo);
            } else {
              setSuccess(true);
            }
          } catch (e) {
            setSubmitting(false);
            setFormError(
              'Something went wrong. Please contact industry@backdrophome.com'
            );
          }
        }}
      >
        {({ isSubmitting, handleSubmit, isValid }) => {
          return success ? (
            <ForgotPasswordSuccess />
          ) : (
            <Form id="digital_gift_card_form" onSubmit={handleSubmit}>
              <Box pad="small">
                <Box
                  className="row"
                  fill="horizontal"
                  justify="between"
                  pad={{ horizontal: 'large', bottom: 'large' }}
                >
                  <Box className="line-item-property__field">
                    <label htmlFor="first_name">Email</label>
                    <Field
                      name="email"
                      id="email"
                      type="email"
                      component={InputText}
                    />
                    <ErrorMessage name="email">
                      {(msg) => (
                        <Text
                          color="status-error"
                          style={{ textAlign: 'left' }}
                        >
                          {msg}
                        </Text>
                      )}
                    </ErrorMessage>
                  </Box>

                  {formError && (
                    <Box pad="medium">
                      <Text color="status-critical">{formError}</Text>
                    </Box>
                  )}
                  <Box
                    align="center"
                    direction="column"
                    fill="horizontal"
                    pad="medium"
                  >
                    <Button
                      primary
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      label="Send Reset Password Link"
                      icon={isSubmitting ? <Loading /> : null}
                      reverse
                      fill
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormForgotPassword.propTypes = {
  returnTo: PropTypes.object,
};

export default memo(FormForgotPassword);
