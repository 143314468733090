import { navigate as gatsbyNav } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import { setActiveModal } from '../state/ui/uiSlice';

function useModalNavigation() {
  const activeModal = useSelector((state) => state.ui.activeModal);
  const dispatch = useDispatch();

  const navigate = (link) => {
    if (activeModal && link.id) {
      dispatch(setActiveModal({ story: link.id }));
    } else {
      gatsbyNav(`/${link.cached_slug ? link.cached_slug : link}`);
    }
  };

  return { navigate };
}

export default useModalNavigation;
