import React from 'react';
import { Box, Heading, Paragraph } from 'grommet';

const ForgotPasswordSuccess = () => {
  return (
    <Box>
      <Heading>Working on it</Heading>
      <Paragraph margin={{ vertical: 'medium' }}>
        We&apos;ve sent an email to your inbox to reset your Industry by
        Backdrop password.
      </Paragraph>
    </Box>
  );
};

export default ForgotPasswordSuccess;
